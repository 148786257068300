export const dataProcess = [
    {  
      accHeader: 'Adquisición de información',
      accBody: 'Adquisición de información de la empresa a través de un cuestionario con el objetivo de conocer sobre su filosofía, entorno, misión, visión, publico objetivo, productos y otros datos relevantes.',
    },
    {  
      accHeader: 'Análisis',
      accBody: 'Analisis de la información obtenida, busqueda de puntos comunes y diferenciadores con respecto a la competencia, sitentización de los valores y objetivos de la empresa.',
    },
    {  
      accHeader: 'Desarrollo de líneas de diseño',
      accBody: 'Desarrollo de diversos conceptos que simbolicen a la marca y bocetación de los mismos.',
   },
   {  
      accHeader: 'Definición',
      accBody: 'Elección de linea de diseño, ajustes y vectorización final.',
   },
   {  
      accHeader: 'Paleta de color y tipografía',
      accBody: 'Elección definiva de paleta de color para formatos digitales y físicos. Elección de la tipografía del logotipo y el eslogan.',
   },
   {  
      accHeader: 'Versiones',
      accBody: 'Creación de versiones de la marca en distintos formatos y sobre distintos fondos.',
   },
   {  
      accHeader: 'Complementos',
      accBody: 'Diseño de elementos complementarios de la marca. Elección de tipografías para utilización en la papelería, web y resto de elementos pertenecientes a la marca. ',
   },
   {  
      accHeader: 'Manual de marca',
      accBody: 'Creación de manual con las versiones del logo original, positivo, negativo, continuo, horizontal, vertical, mínimo, convivencia, grilla, usos incorrectos, paleta de color, tipografía, elementos complementarios y otros.',
   },
   {  
      accHeader: 'Papelería',
      accBody: 'Diseño de papelería y otros items aplicando el logo y los elementos complementarios de la marca previamente realizados.',
   },
   {  
      accHeader: 'Página Web',
      accBody: 'Creación de página web utlizando elementos de la marca. Elección y adaptación de plantilla en el CMS Wordpress para futura administración del cliente.',
   },
    
]
   


export const dataItem = [
   {  
      itemTitle: 'Líneas de diseño',
      itemDesc: 'Buscando alternativas para el isotipo se trabajo sobre conceptos que comunicara el servicios de la empresa. Por una parte se paso se ilustro las 2 partes de un ticket, en representación de la publicidad en el dorso de los tickets de compra, en otra alternativa se representa de forma simbolica la entrega de publicada en mano.',
   },
   {  
      itemTitle: 'Concepto isotipo',
      itemDesc: 'Como isotipo se desidió realizar una forma que evocara tanto a los servicios brindados como a sus beneficios. Para esto se combinó figuras representativas de los mismos hasta llegar a un isotipo que a medida lo observamos podemos ver, un simbolo de ensendido-apagado en referencia al ahorro, dos figuras de personas enfrentadas en referencia a las dos partes, y un rectangulo amarillo en referencia a la publicidad en forma de ticket o talonario que es compartida por las dos partes.',
   },
   {  
      itemTitle: 'Construcción',
      itemDesc: 'El isotipo se contruye en base a figuras geomtricas simples, usando circulos y rectangulos, colocados de forma que creaun una figura equilibrada y horizontalmente simetrica.',
   },
   {  
      itemTitle: 'Color',
      itemDesc: 'Los colores utilizados fueron el azul en represetnación del trato sincero, el profesionalismo y la seriedad de la empresa. El secundario fue el amarillo, color enérgico y expresivo que genera equilibiro junto con el azul para darle un toque mas amigable a la marca. Es el color que primero percive el ojo humano y por este motivo esta asociado a la publicidad.',
   },
   {  
      itemTitle: 'Tipografía',
      itemDesc: 'La tipografía escogida fue Gotham debido a su modernidad, flexibilidad y geometría que complementan a la marca y combina con el isotipo. Esta tipografía cuenta con múltiples variantes de peso lo que la hace aún más versatil, pudiendose usar tanto para títulos como para bloques de texto.',
   },
   {  
      itemTitle: 'Isologotipo',
      itemDesc: 'Para representar a la marca y su nombre se decidió crear un isologotipo, remplazando la segunda letra "O" de PROMON por el isotipo, aprovechando la similitud de las formas. Esto permite ahorrar espacio y refuerza este concepto de la marca. En la versión principal del logotipo se utiliza el azul de la marca para el fondo, el blanco para las letras y las partes del isotipo que simbolizan a las personas, dejando el amarillo únicamente para el rectángulo que simboliza la publicidad, de está forma el amarillo se utiliza en menor proporción generando un acento.',
   },
   {  
      itemTitle: 'Variantes de color',
      itemSubtitle: 'Positivo una tinta',
      itemDesc: 'Versión del logo sobre fondos claros con una sola tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C.',
   },
   {  
      itemTitle: 'Variantes de color',
      itemSubtitle: 'Positivo varias tintas',
      itemDesc: 'Versión del logo sobre fondos claros con mas de una tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C y gris #646464 / Pantone Cool Gray 9 C.',
   },
   {  
      itemTitle: 'Variantes de color',
      itemSubtitle: 'Negativo una tinta',
      itemDesc: 'Versión del logo sobre fondos oscuros con una sola tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C, logo #ffffff / papel blanco',
   },
   {  
      itemTitle: 'Variantes de color',
      itemSubtitle: 'Negativo varias tintas',
      itemDesc: 'Versión del logo sobre fondos oscuros con más de una tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C, gris #9a9a9a / Pantone Cool Gray 9 C, blanco #ffffff / papel blanco.',
   },
   {  
      itemTitle: 'Íconos',
      itemDesc: 'Como elemento complementario de marca se crearon íconos usando como modulos las figuras geometricas que dan forma al isotipo. De esta manera se crea un universo de elementos que siguen la misma estetica y se asocian facilmente a la marca para utilizar tanto en la papelería como en la web.',
   },
   {  
      itemTitle: 'Tramas',
      itemDesc: 'Usando los mismos módulos utilizados para crear los íconos se crearon tramas con la posibiidad de ser usada en papelería corporativa u otros items y con el fin de complementar el universo de la marca con un elemento estético.',
   },
   {  
      quoteText: 'No es secreto que el mundo real dode el diseñador se desempeña, no es el mundo del arte, pero si el mundo de comprar y vender.',
      quoteAutor: 'Paul Rand',
   },


   
]